import { useEffect, useState } from 'react';
import { Api } from '../../../../../../../utils/helpers';
import { FormComponentStatus } from '../../../../../../../utils/constants/enums';
import { Helpers } from '../../../../../../../utils/helpers/Helpers';

const useLegalBusinessInformation = (containerFormData, formStatusCallback) => {
  const [merchantCategoryCodes, setMerchantCategoryCodes] = useState({});
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    businessType: containerFormData.businessType,
    businessMerchantCategoryCode: containerFormData.businessMerchantCategoryCode,
    businessLegalName: containerFormData.businessLegalName,
    businessDBAName: containerFormData.businessDBAName,
    businessEmail: containerFormData.businessEmail,
    businessWebsite: containerFormData.businessWebsite,
    businessPhoneNumber: containerFormData.businessPhoneNumber
  });
  const [loading, setLoading] = useState(true);
  const [defaultSelectValue, setDefaultSelectValue] = useState({ value: '', label: '- Select -' });

  useEffect(() => {
    async function getMerchantCategoryCodes() {
      setLoading(true);
      const apiResponse = await Api.get(`/paypal/Onboarding/MerchantCategoryCodes`);

      if (apiResponse.status.statusCode !== 200) {
        console.error('api error occurred');
        setLoading(false);
        return;
      }

      let mccs = apiResponse.response;

      setMerchantCategoryCodes(mccs);
      setLoading(false);
    }
    getMerchantCategoryCodes();
  }, []);

  useEffect(() => {
    if (containerFormData.businessMerchantCategoryCode) {
      for (const key in merchantCategoryCodes) {
        if (merchantCategoryCodes[key].value === containerFormData.businessMerchantCategoryCode) {
          setDefaultSelectValue({
            value: containerFormData.businessMerchantCategoryCode,
            label: merchantCategoryCodes[key].label
          });
          return;
        }
      }
    }
  }, [containerFormData.businessMerchantCategoryCode, merchantCategoryCodes]);

  const isValidField = (name, value) => {
    //place any custom field validation logic in here
    switch (name) {
      case 'businessWebsite':
        return true; //optional field
      case 'businessDBAName':
        return true; //optional field
      case 'businessPhoneNumber':
        return Helpers.isValidUnformattedPhoneNumber(value);
      default:
        return !Helpers.isNullOrWhitespace(value);
    }
  };

  const isMissingValues = (fields) => {
    return Object.entries(fields).some(([key, value]) => {
      return !isValidField(key, value);
    });
  };

  const [componentStatus, setComponentStatus] = useState(
    isMissingValues(formData) ? FormComponentStatus.INCOMPLETE : FormComponentStatus.COMPLETE
  );

  const updateValue = ({ name, value }) => {
    const fields = { ...formData };
    fields[name] = value;
    setFormData(fields);

    setErrors((err) => {
      return { ...err, [name]: !isValidField(name, value) };
    });

    const missingValues = isMissingValues(fields);
    setComponentStatus(
      missingValues ? FormComponentStatus.INCOMPLETE : FormComponentStatus.COMPLETE
    );
    formStatusCallback('legalBusinessInformation', !missingValues, fields);
  };

  const updateSelect = (option) => {
    const fields = { ...formData };
    fields['businessMerchantCategoryCode'] = option.value;
    setFormData(fields);

    setErrors((err) => {
      return {
        ...err,
        ['businessMerchantCategoryCode']: !isValidField(
          'businessMerchantCategoryCode',
          option.value
        )
      };
    });

    const missingValues = isMissingValues(fields);
    setComponentStatus(
      missingValues ? FormComponentStatus.INCOMPLETE : FormComponentStatus.COMPLETE
    );
    formStatusCallback('legalBusinessInformation', !missingValues, fields);
  };

  return {
    loading,
    merchantCategoryCodes,
    defaultSelectValue,
    componentStatus,
    formData,
    errors,
    updateValue,
    updateSelect
  };
};

export default useLegalBusinessInformation;
