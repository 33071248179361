import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPrint,
  faPaperPlane,
  faBan,
  faDollarSign,
  faUser
} from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import usePaymentConfirmationModal from './usePaymentConfirmationModal';
import { PaymentConfirmationView, PaymentType } from '../../../../utils/constants/enums';
import { ReasonCodes } from '../../../../utils/constants/reasonCodes';
import styles from './PaymentConfirmationModal.module.css';
import { Helpers } from '../../../../utils/helpers';
import './PaymentConfirmationModalPrint.css';
import { AlertMessage } from '../../../../components/ui';

export const PaymentConfirmationModal = ({
  confirmationParams,
  userCanVoid,
  userCanChargeback,
  viewChangeCallback,
  closeCallback,
  reloadSearchCallback
}) => {
  const confirmation = usePaymentConfirmationModal({
    confirmationParams,
    viewChangeCallback,
    reloadSearchCallback
  });

  const hasVoidableItem = confirmation.paymentDetails.lineItems.some((x) => !x.void);
  const hasChargebackableItem = confirmation.paymentDetails.lineItems.some((x) => !x.chargeback);
  const loggedInUserPayment =
    !Helpers.isNullOrWhitespace(confirmation.paymentDetails.membershipUserID) &&
    confirmation.paymentDetails.membershipUserID !== '00000000-0000-0000-0000-000000000000';

  return (
    <>
      <Modal
        show={confirmationParams.confirmationNumber !== null}
        keyboard={true}
        fullscreen={false}
        size="lg"
        className={`paymentConfirmationModal ${styles.paymentConfirmationModalContainer}`}
        dialogClassName={styles.paymentConfirmationModal}
        centered={false}
        onHide={closeCallback}>
        <Modal.Header closeButton>
          <Modal.Title>
            {confirmation.getModalTitle()}
            <div className={styles.modalBadges}>
              {!confirmation.loading && loggedInUserPayment && (
                <div
                  className={styles.badge}
                  title="User was logged in when this payment was made.">
                  <FontAwesomeIcon className="me-2" icon={faUser} />
                  Logged in
                </div>
              )}
              {!confirmation.loading && !loggedInUserPayment && (
                <div className={styles.badge} title="Payment was made by a guest.">
                  <FontAwesomeIcon className="me-2" icon={faUser} />
                  Guest Payment
                </div>
              )}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AlertMessage
            className="rounded-0"
            visible={confirmation.message.content.trim() !== ''}
            message={confirmation.message.content}
            status={confirmation.message.status}
            scrollTo={true}
          />
          <div className="row">
            <div className="col-12">
              {confirmation.loading && <Spinner animation="border" size="md" className="mt-2" />}
              {!confirmation.loading && (
                <>
                  <div className="row conf-button-row">
                    <div className="col-6">
                      {confirmationParams.viewType === PaymentConfirmationView.DEFAULT && (
                        <>
                          {userCanVoid && confirmationParams.canVoid && hasVoidableItem && (
                            <button
                              className="btn btn-primary btn-sm me-2"
                              onClick={() => viewChangeCallback(PaymentConfirmationView.VOID)}>
                              <FontAwesomeIcon className="me-2" icon={faBan} />
                              Void
                            </button>
                          )}
                          {userCanChargeback &&
                            confirmationParams.canChargeback &&
                            hasChargebackableItem && (
                              <button
                                className="btn btn-primary btn-sm"
                                onClick={() =>
                                  viewChangeCallback(PaymentConfirmationView.CHARGEBACK)
                                }>
                                <FontAwesomeIcon className="me-2" icon={faDollarSign} />
                                Chargeback
                              </button>
                            )}
                        </>
                      )}
                      {confirmationParams.viewType !== PaymentConfirmationView.DEFAULT && (
                        <>
                          <button
                            className="btn btn-primary btn-danger btn-sm"
                            onClick={() => viewChangeCallback(PaymentConfirmationView.DEFAULT)}>
                            Cancel Changes
                          </button>
                        </>
                      )}
                    </div>
                    <div className="col-6 text-end">
                      {confirmationParams.viewType === PaymentConfirmationView.DEFAULT && (
                        <>
                          <button
                            className="btn btn-primary btn-sm me-2"
                            disabled={!confirmation.canResendReceipt}
                            onClick={confirmation.resendReceipt}>
                            <FontAwesomeIcon className="me-2" icon={faPaperPlane} />
                            Resend Receipt{' '}
                            {confirmation.processingResendReceipt && (
                              <Spinner animation="border" size="sm" className="ms-2" />
                            )}
                          </button>
                          <button className="btn btn-primary btn-sm" onClick={() => window.print()}>
                            <FontAwesomeIcon className="me-2" icon={faPrint} />
                            Print
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                  <hr className="conf-button-row-divider" />
                  <div className="row">
                    <div className="col-6">
                      <strong>Post Date:</strong>{' '}
                      {Helpers.toShortDateTime(confirmation.paymentDetails.timeProcessed)}
                    </div>
                    <div className="col-6">
                      <strong>Confirmation Number:</strong>{' '}
                      {confirmation.paymentDetails.confirmationNumber}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <strong>Sch. Date:</strong>{' '}
                      {Helpers.toShortDateTime(confirmation.paymentDetails.scheduledDateTime)}
                    </div>
                    <div className="col-6">
                      <strong>Sch. Conf. Number:</strong>{' '}
                      {confirmation.paymentDetails.scheduledConfirmationNumber}
                    </div>
                  </div>
                  <hr></hr>
                  <div className="row">
                    <div className="col-6">
                      <table className={styles.detailTable}>
                        <tbody>
                          <tr>
                            <td>
                              <strong>Name:</strong>
                            </td>
                            <td>{confirmation.paymentDetails.payorName}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Address:</strong>
                            </td>
                            <td>
                              <div>{confirmation.paymentDetails.billingAddress1}</div>
                              {!Helpers.isNullOrWhitespace(
                                confirmation.paymentDetails.billingAddress2
                              ) && <div>{confirmation.paymentDetails.billingAddress2}</div>}
                              {confirmation.paymentDetails.billingCity},{' '}
                              {confirmation.paymentDetails.billingState}{' '}
                              {confirmation.paymentDetails.billingZipCode}
                            </td>
                          </tr>
                          {!Helpers.isNullOrWhitespace(
                            confirmation.paymentDetails.billingPhone
                          ) && (
                            <tr>
                              <td>
                                <strong>Phone:</strong>
                              </td>
                              <td>{confirmation.paymentDetails.billingPhone}</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="col-6">
                      <table className={styles.detailTable}>
                        <tbody>
                          <tr>
                            <td>
                              <strong>Payment Type:</strong>
                            </td>
                            <td>
                              {Helpers.paymentTypeAsText(confirmation.paymentDetails.paymentTypeId)}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Email Address:</strong>
                            </td>
                            <td>
                              <a href={`mailto:${confirmation.paymentDetails.billingEmail}`}>
                                {confirmation.paymentDetails.billingEmail}
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Processor:</strong>
                            </td>
                            <td>{confirmation.paymentDetails.processor}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      {confirmation.paymentDetails.lineItemsOmitted && (
                        <div className="alert alert-warning text-center">
                          This confirmation contains multiple payments. Payments displayed are based
                          on your user access.
                        </div>
                      )}
                      <table className="table">
                        <thead>
                          <tr>
                            {(confirmationParams.viewType === PaymentConfirmationView.VOID ||
                              confirmationParams.viewType ===
                                PaymentConfirmationView.CHARGEBACK) && (
                              <th scope="col" className="text-center">
                                <small>Apply To</small>
                              </th>
                            )}
                            {confirmationParams.viewType === PaymentConfirmationView.CHARGEBACK && (
                              <th scope="col" className="text-center">
                                <small>
                                  Include in ACH
                                  <br />
                                  Chargeback File
                                </small>
                              </th>
                            )}
                            <th scope="col">
                              Transaction{' '}
                              <small style={{ fontWeight: 'normal' }}>(click for details)</small>
                            </th>
                            <th scope="col" className="text-center">
                              Quantity
                            </th>
                            <th scope="col" className="text-end">
                              Amount
                            </th>
                            <th scope="col" className="text-end">
                              Fee
                            </th>
                            <th scope="col" className="text-end">
                              Sub Total
                            </th>
                            {confirmationParams.viewType === PaymentConfirmationView.DEFAULT && (
                              <th scope="col" className="text-center">
                                AutoPay/
                                <br />
                                Recurring
                              </th>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {confirmation.paymentDetails.lineItems.map((transaction) => {
                            return (
                              <tr key={transaction.cpiId} className={styles.transactionRow}>
                                {(confirmationParams.viewType === PaymentConfirmationView.VOID ||
                                  confirmationParams.viewType ===
                                    PaymentConfirmationView.CHARGEBACK) && (
                                  <td className="text-center">
                                    <input
                                      type="checkbox"
                                      checked={transaction.selected}
                                      disabled={
                                        (confirmationParams.viewType ===
                                          PaymentConfirmationView.VOID &&
                                          transaction.void) ||
                                        (confirmationParams.viewType ===
                                          PaymentConfirmationView.CHARGEBACK &&
                                          transaction.chargeback)
                                      }
                                      onChange={() =>
                                        confirmation.toggleTransactionSelection(transaction.cpiId)
                                      }
                                    />
                                  </td>
                                )}
                                {confirmationParams.viewType ===
                                  PaymentConfirmationView.CHARGEBACK && (
                                  <td className="text-center">
                                    <input
                                      type="checkbox"
                                      checked={transaction.selected && transaction.includeAchFile}
                                      disabled={
                                        confirmation.paymentDetails.paymentTypeId ===
                                          PaymentType.PAYPAL_VENMO ||
                                        (confirmation.paymentDetails.paymentTypeId !==
                                          PaymentType.PAYPAL_VENMO &&
                                          !transaction.selected)
                                      }
                                      onChange={() =>
                                        confirmation.toggleAchFileSelection(transaction.cpiId)
                                      }
                                    />
                                  </td>
                                )}
                                <td className={styles.transactionColumn}>
                                  <div>{transaction.customerName}</div>
                                  <a
                                    href="#"
                                    onClick={(e) =>
                                      confirmation.toggleFields(e, transaction.cpiId)
                                    }>
                                    {transaction.transactionName}
                                  </a>
                                  {transaction.void ? ' [VOID]' : ''}
                                  {transaction.chargeback ? ' [CHARGEBACK]' : ''}
                                  {transaction.showFields && (
                                    <table className={styles.fieldTable}>
                                      <tbody>
                                        {transaction.void && (
                                          <>
                                            <tr>
                                              <td>
                                                <strong>Void Date</strong>
                                              </td>
                                              <td>
                                                {transaction.actionDate
                                                  ? Helpers.toShortDateTime(transaction.actionDate)
                                                  : 'Unknown'}
                                              </td>
                                            </tr>
                                          </>
                                        )}
                                        {transaction.chargeback && (
                                          <>
                                            <tr>
                                              <td>
                                                <strong>Chargeback Date</strong>
                                              </td>
                                              <td>
                                                {transaction.actionDate
                                                  ? Helpers.toShortDateTime(transaction.actionDate)
                                                  : 'Unknown'}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <strong>Chargeback Reason</strong>
                                              </td>
                                              <td>{transaction.chargeBackReason ?? 'Unknown'}</td>
                                            </tr>
                                          </>
                                        )}
                                        {transaction.paymentInputFields.length === 0 &&
                                          transaction.paymentCustomFields.length === 0 && (
                                            <tr>
                                              <td>
                                                <em>No fields for this transaction.</em>
                                              </td>
                                            </tr>
                                          )}
                                        {transaction.paymentInputFields.map((field) => {
                                          return (
                                            <tr key={field.inputFieldId}>
                                              <td>
                                                <strong>{field.fieldName}</strong>
                                              </td>
                                              <td>{field.fieldValue}</td>
                                            </tr>
                                          );
                                        })}
                                        {transaction.paymentCustomFields.map((field) => {
                                          return (
                                            <tr key={field.customFieldId}>
                                              <td>
                                                <strong>{field.fieldName}</strong>
                                              </td>
                                              {field.dataType === 'File' && (
                                                <td>
                                                  <a
                                                    href="#"
                                                    onClick={(e) =>
                                                      confirmation.downloadBlobAttachment(
                                                        field.fieldValue
                                                      )
                                                    }>
                                                    {confirmation.formatBlobFileName(
                                                      field.fieldValue
                                                    )}
                                                  </a>
                                                </td>
                                              )}
                                              {field.dataType !== 'File' && (
                                                <td>{field.fieldValue}</td>
                                              )}
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </table>
                                  )}
                                </td>
                                <td className="text-center">{transaction.quantity}</td>
                                <td className="text-end">
                                  {Helpers.formatCurrency(transaction.price)}
                                </td>
                                <td className="text-end">
                                  {Helpers.formatCurrency(transaction.convenienceFee)}
                                </td>
                                <td className="text-end">
                                  {Helpers.formatCurrency(
                                    transaction.price + transaction.convenienceFee
                                  )}
                                </td>
                                {confirmationParams.viewType ===
                                  PaymentConfirmationView.DEFAULT && (
                                  <td className="text-center">
                                    {transaction.autoPayRecurring ?? ''}
                                  </td>
                                )}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      {confirmationParams.viewType === PaymentConfirmationView.CHARGEBACK && (
                        <>
                          <select
                            value={confirmation.reasonCode}
                            onChange={(e) => confirmation.setReasonCode(e.target.value)}>
                            <option value="">- Select Chargeback Reason -</option>
                            {confirmation.paymentDetails.paymentTypeId !==
                              PaymentType.PAYPAL_VENMO &&
                              ReasonCodes.map((item) => (
                                <option key={item.code} value={item.code}>
                                  {item.desc}
                                </option>
                              ))}
                            {confirmation.paymentDetails.paymentTypeId ===
                              PaymentType.PAYPAL_VENMO &&
                              ReasonCodes.filter((item) => item.desc === 'Refund').map((item) => (
                                <option key={item.code} value={item.code}>
                                  {item.desc}
                                </option>
                              ))}
                          </select>
                        </>
                      )}
                      {confirmationParams.viewType !== PaymentConfirmationView.DEFAULT && (
                        <>
                          <button
                            className="btn btn-primary btn-sm ms-2"
                            disabled={
                              confirmation.processingVoidChargeback ||
                              (confirmationParams.viewType === PaymentConfirmationView.CHARGEBACK &&
                                confirmation.reasonCode === '') ||
                              !confirmation.paymentDetails.lineItems.some((t) => t.selected)
                            }
                            onClick={confirmation.applyVoidChargeback}>
                            Apply{' '}
                            {confirmation.processingVoidChargeback && (
                              <Spinner animation="border" size="sm" className="ms-2" />
                            )}
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

PaymentConfirmationModal.propTypes = {
  show: PropTypes.bool,
  confirmationNumber: PropTypes.string,
  closeCallback: PropTypes.func.isRequired
};

export default PaymentConfirmationModal;
