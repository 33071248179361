import { useState, useRef, useCallback, useEffect } from 'react';
import { Api } from '../../../../utils/helpers';
import {
  AlertStatus,
  PaymentConfirmationView,
  PaymentType
} from '../../../../utils/constants/enums';

const usePaymentConfirmationModal = ({
  confirmationParams,
  viewChangeCallback,
  reloadSearchCallback
}) => {
  const bffApiUrl = process.env.REACT_APP_BFF_API_URL;
  const messageTimer = useRef(null);
  const [message, setMessage] = useState({
    status: AlertStatus.INFO,
    content: ''
  });
  const [loading, setLoading] = useState(false);
  const [processingVoidChargeback, setProcessingVoidChargeback] = useState(false);
  const [processingResendReceipt, setProcessingResendReceipt] = useState(false);
  const [reasonCode, setReasonCode] = useState('');
  const [showImportedInfo, setShowImportedInfo] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState({
    confirmationNumber: confirmationParams?.confirmationNumber,
    postDate: null,
    schDate: null,
    schConfNumber: '',
    name: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    phone: '',
    paymentType: '',
    emailAddress: '',
    autoPay: '',
    paymentTypeId: '',
    lineItems: []
  });

  const getModalTitle = () => {
    switch (confirmationParams.viewType) {
      case PaymentConfirmationView.VOID:
        return 'Void';
      case PaymentConfirmationView.CHARGEBACK:
        return 'Chargeback';
      default:
        return 'Payment Confirmation';
    }
  };

  const showMessage = (msg, type, timeout) => {
    clearTimeout(messageTimer.current);
    setMessage({
      status: type,
      content: msg
    });
    if (timeout && typeof timeout === 'number') {
      messageTimer.current = setTimeout(() => clearMessage(), timeout);
    }
  };

  const clearMessage = () => {
    clearTimeout(messageTimer.current);
    setMessage({
      status: AlertStatus.INFO,
      content: ''
    });
  };

  const toggleFields = (e, cpiId) => {
    e.preventDefault();
    let details = { ...paymentDetails };
    let transaction = details.lineItems.find((tx) => tx.cpiId === cpiId);
    if (!transaction) {
      return;
    }
    transaction.showFields = !transaction.showFields;
    setPaymentDetails(details);
  };

  const resendReceipt = async () => {
    clearMessage();
    if (processingResendReceipt) {
      return;
    }
    setProcessingResendReceipt(true);

    const receiptParams = {
      brandId: confirmationParams.brandId,
      source: confirmationParams.source,
      confirmationNumber: confirmationParams.confirmationNumber
    };
    const apiResponse = await Api.post(
      '/data/Research/ResendReceipt',
      JSON.stringify(receiptParams)
    );
    if (apiResponse.status.statusCode !== 200) {
      showMessage(`Server error occurred while resending receipt.`, AlertStatus.ERROR, 8000);
      setProcessingResendReceipt(false);
      return;
    }

    if (apiResponse.response) {
      //success - set to payment confirmation view and reload
      showMessage('Receipt sent successfully.', AlertStatus.SUCCESS, 4000);
    } else {
      //failed - show message
      showMessage('Unable to send receipt.', AlertStatus.WARNING, 4000);
    }
    setProcessingResendReceipt(false);
  };

  const toggleTransactionSelection = (cpiId) => {
    let details = { ...paymentDetails };
    let transaction = details.lineItems.find((t) => t.cpiId === cpiId);
    if (transaction) {
      transaction.selected = !transaction.selected;
      if (!transaction.selected) {
        transaction.includeAchFile = false;
      }
      setPaymentDetails(details);
    }
  };

  const toggleAchFileSelection = (cpiId) => {
    let details = { ...paymentDetails };
    let transaction = details.lineItems.find((t) => t.cpiId === cpiId);
    if (transaction) {
      transaction.includeAchFile = !transaction.includeAchFile;
      setPaymentDetails(details);
    }
  };

  const applyVoidChargeback = async () => {
    if (processingVoidChargeback) {
      return;
    }
    setProcessingVoidChargeback(true);

    let endpoint = null;
    let postBody = null;
    let payPalBody = null;
    let payPalError = false;
    let payPalErrorMessage = '';

    if (confirmationParams.viewType === PaymentConfirmationView.VOID) {
      endpoint = 'Void';
      postBody = {
        brandId: confirmationParams.brandId,
        voids: paymentDetails.lineItems
          .filter((item) => item.selected)
          .map((item) => {
            return {
              cpiId: item.cpiId
            };
          })
      };
    } else if (confirmationParams.viewType === PaymentConfirmationView.CHARGEBACK) {
      endpoint = 'Chargeback';
      postBody = {
        brandId: confirmationParams.brandId,
        chargebacks: paymentDetails.lineItems
          .filter((item) => item.selected)
          .map((item) => {
            return {
              cpiId: item.cpiId,
              includeInAch: item.includeAchFile,
              reasonCode: reasonCode
            };
          })
      };
      if (paymentDetails.paymentTypeId === PaymentType.PAYPAL_VENMO) {
        payPalBody = {
          siteId: confirmationParams.brandId,
          confirmationNumber: paymentDetails.confirmationNumber,
          lineItems: paymentDetails.lineItems
            .filter((item) => item.selected)
            .map((item) => {
              return {
                cartPaymentIntersectId: item.cpiId,
                transactionId: item.transactionID,
                transactionName: item.transactionName,
                payPalReferenceId: item.referenceId,
                amount: item.price.toString()
              };
            })
        };
        const payPalResponse = await Api.post(`/paypal/Refund`, JSON.stringify(payPalBody));
        if (payPalResponse.status.statusCode !== 200) {
          setProcessingVoidChargeback(false);
          showMessage(
            `Server error occurred while processing ${getModalTitle().toLowerCase()} for PayPal/Venmo.`,
            AlertStatus.ERROR,
            8000
          );
          setProcessingVoidChargeback(false);
          return;
        }
        if (payPalResponse.response.hasFailures) {
          payPalError = true;
          payPalErrorMessage = payPalResponse.response.failureReason;
          let payPalRefunds = payPalResponse.response.lineItems
            .filter((line) => line.refunded)
            .map((line) => {
              return {
                cpiId: line.cartPaymentIntersectId
              };
            });
          postBody = {
            brandId: confirmationParams.brandId,
            chargebacks: paymentDetails.lineItems
              .filter(
                (item) => item.selected && payPalRefunds.find((elem) => elem.cpiId === item.cpiId)
              )
              .map((item) => {
                return {
                  cpiId: item.cpiId,
                  includeInAch: item.includeAchFile,
                  reasonCode: reasonCode
                };
              })
          };
        }
      }
    } else {
      //invalid view type
      return;
    }

    const apiResponse = await Api.post(`/data/Research/${endpoint}`, JSON.stringify(postBody));
    if (apiResponse.status.statusCode !== 200) {
      setProcessingVoidChargeback(false);
      if (payPalError) {
        showMessage(payPalErrorMessage, AlertStatus.ERROR, 10000);
      } else {
        showMessage(
          `Server error occurred while processing ${getModalTitle().toLowerCase()}.`,
          AlertStatus.ERROR,
          8000
        );
      }
      return;
    }

    if (apiResponse.response && !payPalError) {
      //success - set to payment confirmation view and reload
      viewChangeCallback(PaymentConfirmationView.DEFAULT);
      reloadSearchCallback();
      await loadPaymentDetails();
      showMessage(`${getModalTitle()} processed successfully.`, AlertStatus.SUCCESS, 4000);
    } else if (payPalError) {
      //at least one PayPal/Venmo failed, other lines may have succeeded
      //reload so we can see the status label if any lines succeeded
      viewChangeCallback(PaymentConfirmationView.CHARGEBACK);
      reloadSearchCallback();
      await loadPaymentDetails();
      showMessage(payPalErrorMessage, AlertStatus.ERROR, 10000);
    } else {
      //failed - show message
      showMessage(
        `Unable to process your ${getModalTitle().toLowerCase()} request.`,
        AlertStatus.WARNING,
        8000
      );
    }
    setProcessingVoidChargeback(false);
  };

  const loadPaymentDetails = useCallback(async () => {
    if (!confirmationParams.confirmationNumber) {
      return;
    }
    setLoading(true);

    //clear messages and reset reason code
    clearMessage();
    setReasonCode('');

    const receiptParams = {
      brandId: confirmationParams.brandId,
      source: confirmationParams.source,
      confirmationNumber: confirmationParams.confirmationNumber
    };

    const apiResponse = await Api.post('/data/Research/GetReceipt', JSON.stringify(receiptParams));
    if (apiResponse.status.statusCode !== 200) {
      console.error('api error occurred');
      setLoading(false);
      return;
    }

    //add properties needed for void and chargebacks
    apiResponse.response.lineItems.forEach((item) => {
      item.selected = false;
      item.includeAchFile = false;
    });

    setPaymentDetails(apiResponse.response);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmationParams.confirmationNumber]);

  useEffect(() => {
    //automatically trigger load if confirmation number changes
    loadPaymentDetails();
  }, [loadPaymentDetails, confirmationParams.confirmationNumber]);

  const canResendReceipt = !paymentDetails?.lineItems.some((x) => x.void || x.chargeback) ?? false;

  const formatBlobFileName = (blobName) => {
    //strip out blob container info to display the original file name
    const lastSlash = blobName.lastIndexOf('/');

    if (lastSlash !== -1) {
      return blobName.slice(lastSlash + 1, blobName.Len);
    } else {
      return blobName;
    }
  };

  const downloadBlobAttachment = (blobName) => {
    const downloadPath = `${bffApiUrl}/files/fileUpload/download?path=${encodeURIComponent(
      blobName
    )}`;
    window.location.href = downloadPath;
  };

  return {
    message,
    loading,
    paymentDetails,
    showImportedInfo,
    reasonCode,
    processingVoidChargeback,
    processingResendReceipt,
    canResendReceipt,
    getModalTitle,
    setShowImportedInfo,
    toggleFields,
    resendReceipt,
    toggleTransactionSelection,
    toggleAchFileSelection,
    setReasonCode,
    applyVoidChargeback,
    formatBlobFileName,
    downloadBlobAttachment
  };
};

export default usePaymentConfirmationModal;
